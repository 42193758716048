<template>
  <div>
    <b-card>
      <b-breadcrumb class="pl-0" :items="breadcrumbItems"/>
    </b-card>

    <b-card :title="eventName">
      <validation-observer ref="simpleRules">
          <b-row>
            <!-- Full Name -->
            <b-col md="6">
              <b-form-group
                  label="Full Name"
                  label-for="name"
              >
                <b-form-input
                    id="name"
                    placeholder="Full Name"
                    v-model="name"
                    disabled
                />
              </b-form-group>
            </b-col>

            <!-- Contact No. -->
            <b-col md="6">
              <b-form-group
                  label="Contact No."
                  label-for="phone_number"
              >
                <b-form-input
                    id="phone_number"
                    placeholder="Contact No."
                    v-model="phoneNumber"
                    disabled
                />
              </b-form-group>
            </b-col>

            <!-- Email -->
            <b-col md="6">
              <b-form-group
                  label="Email"
                  label-for="email"
              >
                <b-form-input
                    id="email"
                    placeholder="Email"
                    v-model="email"
                    disabled
                />
              </b-form-group>
            </b-col>

            <!-- Attendance -->
            <b-col md="6">
              <b-form-group
                  label="Attendance"
                  label-for="has_attended"
              >
                <b-form-checkbox
                    :checked="form.has_attended"
                    v-model="form.has_attended"
                    switch
                    size="lg"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <!-- Occupation -->
            <b-col md="6">
              <b-form-group
                  label="Occupation"
                  label-for="occupation"
              >
                <b-form-input
                    id="occupation"
                    placeholder="Occupation"
                    v-model="form.occupation"
                />
              </b-form-group>
            </b-col>

            <!-- Social Media Link -->
            <b-col md="6">
              <b-form-group
                  label="Social Media Link"
                  label-for="social_media_link"
              >
                <b-form-input
                    id="social_media_link"
                    placeholder="Social Media Link"
                    v-model="form.social_media_link"
                />
              </b-form-group>
            </b-col>

            <!-- Remark -->
            <b-col md="6">
              <b-form-group
                  label="Guest's hobbies / lifestyle / remark"
                  label-for="remark"
              >
                <b-form-input
                    id="remark"
                    placeholder="Guest's hobbies / lifestyle / remark"
                    v-model="form.remark"
                />
              </b-form-group>
            </b-col>

            <!-- Save Changes button -->
            <b-col cols="12">
              <b-button
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  @click="validateForm"
              >
                Save Changes
              </b-button>
              <b-button
                  :to="{ name: 'event.attendance.index', params: { eventId: this.$route.params.eventId } }"
                  variant="outline-secondary"
                  class="mr-1">
                Cancel
              </b-button>
            </b-col>
          </b-row>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCardText,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  BRow,
  BBreadcrumb,
  BCard,
  BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import { required } from '@validations'

export default {
  components: {
    BBreadcrumb,
    BButton,
    BCard,
    BCardText,
    BCol,
    BForm,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BRow,
    ValidationObserver,
    ValidationProvider,
  },
  created() {
    this.$http.get(`/api/event/${this.eventId}/attendance/${this.userId}`)
        .then(res => {
          let data = res.data.data
          this.form.has_attended = data.has_attended
          this.form.occupation = data.occupation
          this.form.social_media_link = data.social_media_link
          this.form.remark = data.remark

          this.name = data.name
          this.phoneNumber = data.phone_number
          this.email = data.email
          this.eventName = data.event_name
        })
        .catch(error => {
          this.showErrorToast(error);
        });
  },
  data() {
    return {
      eventId: this.$route.params.eventId,
      userId: this.$route.params.userId,
      name: '',
      phoneNumber: '',
      email: '',
      eventName: '',
      breadcrumbItems: [
        {
          text: 'Event Listing',
          to: { name: 'event.index' },
        },
        {
          text: 'RSVP Guest Listing',
          to: { name: 'event.attendance.index', params: { eventId: this.$route.params.eventId } },
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
      form: {
        _method: 'PATCH',
        has_attended: '',
        occupation: '',
        social_media_link: '',
        remark: '',
      },
    }
  },
  methods: {
    validateForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.update()
        } else {
          reject()
        }
      })
    },
    update(){
      this.$http.post(`/api/event/${this.eventId}/attendance/${this.userId}`, this.form)
          .then(() => {
            this.$swal({
              icon: 'success',
              text: 'RSVP guest detail has been updated.',
              showConfirmButton: false,
              timer: 2000
            }).then(e => {
              this.$router.replace({name:'event.attendance.index', params: {eventId: this.eventId} })
            });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'AlertCircleIcon',
                text: error,
                variant: 'danger',
              },
            });
          })
    }
  }
}
</script>
